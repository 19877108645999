import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './news.css'

const News = (props) => {
  return (
    <div className="news-container1">
      <Helmet>
        <title>
          News - iCharge.app - Stacione dhe pika karikimi për makina elektrike.
        </title>
        <meta
          name="description"
          content="iCharge.app: Zgjidhja inovative për makina elektrike. Zbuloni stacionet dhe pikat më të mira të karikimit me nje sherbim superior ne krahasim me Vega Group."
        />
        <meta
          property="og:title"
          content="News - iCharge.app - Stacione dhe pika karikimi për makina elektrike."
        />
        <meta
          property="og:description"
          content="Zbuloni iCharge.app, platforma juaj për karikimin e makinave elektrike në Shqipëri. "
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d334a72c-d322-4e02-af2f-aa1a000a9ada/f77c7958-1225-4afe-a283-098a54e58587?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <header className="news-header1">
        <header data-thq="thq-navbar" className="news-navbar">
          <div className="news-left">
            <Link to="/" className="news-navlink1">
              <img
                alt="image"
                src="/logo_icharge_primary_1%20(1)-200h.png"
                loading="eager"
                className="news-logo"
              />
            </Link>
          </div>
          <div data-thq="thq-navbar-btn-group" className="news-right"></div>
          <div data-thq="thq-burger-menu" className="news-burger-menu">
            <svg viewBox="0 0 1024 1024" className="news-icon10">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="news-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="news-nav1"
            >
              <div className="news-container2">
                <Link to="/" className="news-navlink2">
                  <img
                    alt="image"
                    src="/logo_icharge_primary_1%20(1)-200h.png"
                    className="news-image1"
                  />
                </Link>
                <div data-thq="thq-close-menu" className="news-menu-close">
                  <svg viewBox="0 0 1024 1024" className="news-icon12">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="news-nav2"
              >
                <Link to="/" className="news-navlink3">
                  Home
                </Link>
                <Link to="/" className="news-navlink4">
                  Rreth Nesh
                </Link>
                <a
                  href="https://m.icharge.app/#/homepage"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="news-link10"
                >
                  Pike Karikimi
                </a>
                <Link to="/" className="news-navlink5">
                  Kontakt
                </Link>
              </nav>
            </div>
          </div>
          <nav className="news-links1">
            <Link to="/" className="news-link11">
              Home
            </Link>
            <Link to="/" className="news-link12">
              Rreth Nesh
            </Link>
            <a
              href="https://m.icharge.app/#/homepage"
              target="_blank"
              rel="noreferrer noopener"
              className="news-link13"
            >
              Pika Karikimi
            </a>
            <span className="news-link14">Fushata</span>
            <a href="#footer" className="news-link15">
              Kontakt
            </a>
          </nav>
        </header>
      </header>
      <section className="news-why">
        <div className="news-header2">
          <div data-aos="fade-right" className="news-heading1">
            <h2 className="news-title">
              Si të gjesh stacionet më të afërta për karikimin e makinave
              elektrike në Shqipëri
            </h2>
          </div>
        </div>
        <div className="news-brands">
          <div className="news-row">
            <div className="news-item">
              <img
                alt="image"
                src="/icharge%20web%20(1).svg"
                className="news-image2"
              />
              <div className="news-details">
                <p className="news-description">
                  <span className="news-text10">
                    Si të përdorësh iCharge.app për të gjetur stacionet më të
                    afërta.
                  </span>
                  <br className="news-text11"></br>
                  <br className="news-text12"></br>
                  <span>
                    Aplikacioni iCharge.app të ndihmon të gjesh dhe përdorësh
                    stacionet e karikimit në mënyrë të thjeshtë. Mjafton të
                    hapësh aplikacionin, të lejosh qasjen në vendndodhje dhe të
                    shikosh hartën për stacionet më të afërta. Në çdo stacion,
                    mund të kontrollosh fuqinë e karikimit, statusin aktual dhe
                    tarifat për të marrë vendimin më të mirë para se të
                    mbërrish.
                  </span>
                  <br className="news-text14"></br>
                  <br className="news-text15"></br>
                  <span className="news-text16">
                    Qytetet kryesore me stacione të karikimit.
                  </span>
                  <br className="news-text17"></br>
                  <br className="news-text18"></br>
                  <span>
                    Në Shqipëri, mund të gjesh stacione karikimi në qytetet
                    kryesore. Tirana ka rrjetin më të madh të stacioneve, me
                    pika të vendosura pranë qendrave tregtare duke ofruar
                    lehtësi për përdoruesit. Gjithashtu, qytete si Shkodra,
                    Vlora dhe Korça kanë stacione të disponueshme për udhëtarët
                    dhe turistët, duke siguruar një eksperiencë të qetë për të
                    gjithë drejtuesit e makinave elektrike.
                  </span>
                  <br className="news-text20"></br>
                  <br className="news-text21"></br>
                  <span className="news-text22">
                    Si të kontrollosh disponueshmërinë në kohë reale.
                  </span>
                  <br className="news-text23"></br>
                  <br className="news-text24"></br>
                  <span>
                    Me iCharge.app, mund të shikosh statusin e drejtpërdrejtë të
                    çdo stacioni, nëse është i lirë, në përdorim apo joaktiv.
                    Ngjyra blu tregon se një stacion është i zënë, duke të
                    ndihmuar të identifikosh më lehtë opsionet e disponueshme.
                    Kjo të mundëson të planifikosh më mirë ndalesat gjatë
                    udhëtimit dhe të shmangësh pritjet e panevojshme.
                  </span>
                  <br className="news-text26"></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="footer" className="news-footer">
        <div className="news-content">
          <div className="news-information">
            <div className="news-heading2">
              <a href="##content" className="news-link16">
                <img
                  alt="pastedImage"
                  src="/logo_icharge_primary_page-0002-removebg-preview-200h-200h-200h.png"
                  loading="lazy"
                  className="news-pasted-image1"
                />
              </a>
              <span className="news-text27">
                <span>Adresa: Rruga e Kavajes,</span>
                <br></br>
                <span>km i 1, Godina ne krahe te Vetingut.</span>
              </span>
            </div>
            <div className="news-socials">
              <a
                href="https://www.instagram.com/icharge.alb/"
                target="_blank"
                rel="noreferrer noopener"
                className="news-link17"
              >
                <div className="news-social1">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="news-icon14"
                  >
                    <circle
                      r="1.44"
                      cx="22.406"
                      cy="9.594"
                      fill="currentColor"
                    ></circle>
                    <path
                      d="M16 9.838A6.162 6.162 0 1 0 22.162 16A6.16 6.16 0 0 0 16 9.838M16 20a4 4 0 1 1 4-4a4 4 0 0 1-4 4"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M16 6.162c3.204 0 3.584.012 4.849.07a6.6 6.6 0 0 1 2.228.413a3.98 3.98 0 0 1 2.278 2.278a6.6 6.6 0 0 1 .413 2.228c.058 1.265.07 1.645.07 4.85s-.012 3.583-.07 4.848a6.6 6.6 0 0 1-.413 2.228a3.98 3.98 0 0 1-2.278 2.278a6.6 6.6 0 0 1-2.228.413c-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07a6.6 6.6 0 0 1-2.228-.413a3.98 3.98 0 0 1-2.278-2.278a6.6 6.6 0 0 1-.413-2.228c-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849a6.6 6.6 0 0 1 .413-2.228a3.98 3.98 0 0 1 2.278-2.278a6.6 6.6 0 0 1 2.228-.413c1.265-.058 1.645-.07 4.849-.07M16 4c-3.259 0-3.668.014-4.948.072a8.8 8.8 0 0 0-2.912.558a6.14 6.14 0 0 0-3.51 3.51a8.8 8.8 0 0 0-.558 2.913C4.014 12.333 4 12.74 4 16s.014 3.668.072 4.948a8.8 8.8 0 0 0 .558 2.912a6.14 6.14 0 0 0 3.51 3.51a8.8 8.8 0 0 0 2.913.558c1.28.058 1.688.072 4.947.072s3.668-.014 4.948-.072a8.8 8.8 0 0 0 2.913-.558a6.14 6.14 0 0 0 3.51-3.51a8.8 8.8 0 0 0 .557-2.913C27.986 19.667 28 19.26 28 16s-.014-3.668-.072-4.948a8.8 8.8 0 0 0-.558-2.912a6.14 6.14 0 0 0-3.51-3.51a8.8 8.8 0 0 0-2.913-.557C19.667 4.013 19.26 4 16 4"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.tiktok.com/@icharge.app"
                target="_blank"
                rel="noreferrer noopener"
                className="news-link18"
              >
                <div className="news-social2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="news-icon18"
                  >
                    <path
                      d="M16.6 5.82s.51.5 0 0A4.28 4.28 0 0 1 15.54 3h-3.09v12.4a2.59 2.59 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.linkedin.com/feed/"
                target="_blank"
                rel="noreferrer noopener"
                className="news-link19"
              >
                <div className="news-social3">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="news-icon20"
                  >
                    <g fill="currentColor">
                      <path
                        d="M12.51 8.796v1.697a3.74 3.74 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766c-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483a1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.6 1.6 0 0 1 1.6 1.606"
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                      ></path>
                      <path d="M7.2 8.809H4V19.5h3.2z"></path>
                    </g>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.facebook.com/icharge.alb"
                target="_blank"
                rel="noreferrer noopener"
                className="news-link20"
              >
                <div className="news-social4">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="news-icon24"
                  >
                    <path
                      d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6z"
                      fill="currentColor"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
          <div className="news-links2">
            <div className="news-column1">
              <span className="news-header3">iCharge.app</span>
              <Link to="/" className="news-link21">
                Home
              </Link>
              <Link to="/" className="news-link22">
                Rreth nesh
              </Link>
              <a
                href="https://m.icharge.app/#/homepage"
                target="_blank"
                rel="noreferrer noopener"
                className="news-link23"
              >
                Pika Karikimi
              </a>
              <Link to="/kushtet-e-perdorimit" className="news-link24">
                Kushtet e perdorimit
              </Link>
              <Link to="/politikat-e-privatesise" className="news-link25">
                Politikat e privatesise
              </Link>
            </div>
            <div className="news-column2">
              <span className="news-header4">Kontakt</span>
              <a href="tel:+355694080227" className="news-link26">
                Tel: +355694080227
              </a>
              <a
                href="mailto:office@icharge.app?subject="
                className="news-link27"
              >
                Email: office@icharge.app
              </a>
            </div>
          </div>
        </div>
        <div className="news-pills">
          <div className="news-container3">
            <div className="news-you-tube1">
              <a
                href="https://apps.apple.com/lv/app/icharge-app/id6479523452"
                target="_blank"
                rel="noreferrer noopener"
                className="news-link28"
              >
                <img
                  alt="pastedImage"
                  src="/Icons/download%20(3)-600w.png"
                  className="news-pasted-image2"
                />
              </a>
            </div>
            <div className="news-you-tube2">
              <a
                href="https://play.google.com/store/apps/details?id=com.icharge.mobile.app"
                target="_blank"
                rel="noreferrer noopener"
                className="news-link29"
              >
                <img
                  alt="pastedImage"
                  src="/Icons/download%20(4)-200h.png"
                  className="news-pasted-image3"
                />
              </a>
            </div>
          </div>
        </div>
        <span className="news-text31">
          © 2024 iCharge. All Rights Reserved.
        </span>
      </div>
    </div>
  )
}

export default News
