import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './politikat-e-privatesise.css'

const PolitikatEPrivatesise = (props) => {
  return (
    <div className="politikat-e-privatesise-container1">
      <Helmet>
        <title>
          Politikat-e-privatesise - iCharge.app - Stacione dhe pika karikimi për
          makina elektrike.
        </title>
        <meta
          name="description"
          content="iCharge.app: Zgjidhja inovative për makina elektrike. Zbuloni stacionet dhe pikat më të mira të karikimit me nje sherbim superior ne krahasim me Vega Group."
        />
        <meta
          property="og:title"
          content="Politikat-e-privatesise - iCharge.app - Stacione dhe pika karikimi për makina elektrike."
        />
        <meta
          property="og:description"
          content="Zbuloni iCharge.app, platforma juaj për karikimin e makinave elektrike në Shqipëri. "
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d334a72c-d322-4e02-af2f-aa1a000a9ada/f77c7958-1225-4afe-a283-098a54e58587?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <header className="politikat-e-privatesise-header1">
        <header
          data-thq="thq-navbar"
          className="politikat-e-privatesise-navbar"
        >
          <div className="politikat-e-privatesise-left">
            <Link to="/" className="politikat-e-privatesise-navlink1">
              <img
                alt="image"
                src="/logo_icharge_primary_1%20(1)-200h.png"
                loading="eager"
                className="politikat-e-privatesise-logo"
              />
            </Link>
          </div>
          <div
            data-thq="thq-navbar-btn-group"
            className="politikat-e-privatesise-right"
          ></div>
          <div
            data-thq="thq-burger-menu"
            className="politikat-e-privatesise-burger-menu"
          >
            <svg
              viewBox="0 0 1024 1024"
              className="politikat-e-privatesise-icon10"
            >
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="politikat-e-privatesise-mobile-menu"
          >
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="politikat-e-privatesise-nav1"
            >
              <div className="politikat-e-privatesise-container2">
                <Link to="/" className="politikat-e-privatesise-navlink2">
                  <img
                    alt="image"
                    src="/logo_icharge_primary_1%20(1)-200h.png"
                    className="politikat-e-privatesise-image"
                  />
                </Link>
                <div
                  data-thq="thq-close-menu"
                  className="politikat-e-privatesise-menu-close"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="politikat-e-privatesise-icon12"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="politikat-e-privatesise-nav2"
              >
                <Link to="/" className="politikat-e-privatesise-navlink3">
                  Home
                </Link>
                <Link to="/" className="politikat-e-privatesise-navlink4">
                  Rreth Nesh
                </Link>
                <a
                  href="https://m.icharge.app/#/homepage"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="politikat-e-privatesise-link10"
                >
                  Pike Karikimi
                </a>
                <Link to="/" className="politikat-e-privatesise-navlink5">
                  Kontakt
                </Link>
              </nav>
            </div>
          </div>
          <nav className="politikat-e-privatesise-links1">
            <Link to="/" className="politikat-e-privatesise-link11">
              Home
            </Link>
            <Link to="/" className="politikat-e-privatesise-link12">
              Rreth Nesh
            </Link>
            <a
              href="https://m.icharge.app/#/homepage"
              target="_blank"
              rel="noreferrer noopener"
              className="politikat-e-privatesise-link13"
            >
              Pika Karikimi
            </a>
            <a href="#footer" className="politikat-e-privatesise-link14">
              Kontakt
            </a>
          </nav>
        </header>
      </header>
      <div className="politikat-e-privatesise-container3 thq-section-padding">
        <div className="politikat-e-privatesise-max-width thq-section-max-width">
          <div className="politikat-e-privatesise-content7 thq-flex-column">
            <ul className="politikat-e-privatesise-ul thq-flex-column">
              <li className="thq-flex-column list-item">
                <h2 className="politikat-e-privatesise-heading1 thq-heading-2">
                  Privacy Policy for iCharge.app
                </h2>
                <h3 className="politikat-e-privatesise-heading21 thq-heading-3">
                  Last updated: March 21, 2024
                </h3>
                <p className="politikat-e-privatesise-content1 thq-body-small">
                  <span>
                    This Privacy Policy describes Our policies and procedures on
                    the collection, use and disclosure of Your information when
                    You use the Service and tells You about Your privacy rights
                    and how the law protects You.
                  </span>
                  <br className="politikat-e-privatesise-text101"></br>
                  <br></br>
                  <span>
                    We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection
                    and use of information in accordance with this Privacy
                    Policy.
                  </span>
                  <br className="politikat-e-privatesise-text104"></br>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading22 thq-heading-3">
                  Interpretation and Definitions
                </h3>
                <p className="politikat-e-privatesise-content2 thq-body-small">
                  <span className="politikat-e-privatesise-text106">
                    Interpretation
                  </span>
                  <br className="politikat-e-privatesise-text107"></br>
                  <br className="politikat-e-privatesise-text108"></br>
                  <br className="politikat-e-privatesise-text109"></br>
                  <span className="politikat-e-privatesise-text110">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </span>
                  <br className="politikat-e-privatesise-text111"></br>
                  <br className="politikat-e-privatesise-text112"></br>
                  <span className="politikat-e-privatesise-text113">
                    Definitions
                  </span>
                  <br className="politikat-e-privatesise-text114"></br>
                  <br className="politikat-e-privatesise-text115"></br>
                  <span className="politikat-e-privatesise-text116">
                    For the purposes of this Privacy Policy:
                  </span>
                  <br className="politikat-e-privatesise-text117"></br>
                  <br className="politikat-e-privatesise-text118"></br>
                  <span className="politikat-e-privatesise-text119"></span>
                  <span className="politikat-e-privatesise-text120">
                    Account
                  </span>
                  <span className="politikat-e-privatesise-text121">
                    {' '}
                    means a unique account created for You to access our Service
                    or parts of our Service.
                  </span>
                  <br className="politikat-e-privatesise-text122"></br>
                  <br className="politikat-e-privatesise-text123"></br>
                  <span className="politikat-e-privatesise-text124"></span>
                  <span className="politikat-e-privatesise-text125">
                    Affiliate
                  </span>
                  <span className="politikat-e-privatesise-text126">
                    {' '}
                    means an entity that controls, is controlled by or is under
                    common control with a party, where &quot;control&quot; means
                    ownership of 50% or more of the 
                  </span>
                  <span className="politikat-e-privatesise-text127">
                    shares, equity interest or other securities entitled to vote
                    for election of directors or other managing authority.
                  </span>
                  <br className="politikat-e-privatesise-text128"></br>
                  <br className="politikat-e-privatesise-text129"></br>
                  <span className="politikat-e-privatesise-text130">
                    Application
                  </span>
                  <span className="politikat-e-privatesise-text131">
                    {' '}
                    refers to iCharge.app, the software program provided by the
                    Company.
                  </span>
                  <br className="politikat-e-privatesise-text132"></br>
                  <br className="politikat-e-privatesise-text133"></br>
                  <span className="politikat-e-privatesise-text134">
                    Company
                  </span>
                  <span className="politikat-e-privatesise-text135">
                    {' '}
                    (referred to as either &quot;the Company&quot;,
                    &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                    Agreement) refers to iCharge Albania, Rr. Barikadave, 123.
                  </span>
                  <br className="politikat-e-privatesise-text136"></br>
                  <br className="politikat-e-privatesise-text137"></br>
                  <span className="politikat-e-privatesise-text138"></span>
                  <span className="politikat-e-privatesise-text139">
                    Country
                  </span>
                  <span className="politikat-e-privatesise-text140">
                    {' '}
                    refers to: Albania
                  </span>
                  <br className="politikat-e-privatesise-text141"></br>
                  <br className="politikat-e-privatesise-text142"></br>
                  <span className="politikat-e-privatesise-text143"></span>
                  <span className="politikat-e-privatesise-text144">
                    Device
                  </span>
                  <span className="politikat-e-privatesise-text145">
                    {' '}
                    means any device that can access the Service such as a
                    computer, a cellphone or a digital tablet.
                  </span>
                  <br className="politikat-e-privatesise-text146"></br>
                  <br className="politikat-e-privatesise-text147"></br>
                  <span className="politikat-e-privatesise-text148">
                    Personal Data
                  </span>
                  <span className="politikat-e-privatesise-text149">
                    {' '}
                    is any information that relates to an identified or
                    identifiable individual.
                  </span>
                  <br className="politikat-e-privatesise-text150"></br>
                  <br className="politikat-e-privatesise-text151"></br>
                  <span className="politikat-e-privatesise-text152">
                    Service
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="politikat-e-privatesise-text153">
                    refers to the Application.
                  </span>
                  <br className="politikat-e-privatesise-text154"></br>
                  <br className="politikat-e-privatesise-text155"></br>
                  <span className="politikat-e-privatesise-text156">
                    Service Provider
                  </span>
                  <span className="politikat-e-privatesise-text157">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="politikat-e-privatesise-text158">
                    means any natural or legal person who processes the data on
                    behalf of the Company. It refers to third-party companies or
                    individuals employed by the Company to facilitate the
                    Service, to provide the Service on behalf of the Company, to
                    perform services related to the Service or to assist the
                    Company in analyzing how the Service is used.
                  </span>
                  <br className="politikat-e-privatesise-text159"></br>
                  <br className="politikat-e-privatesise-text160"></br>
                  <span className="politikat-e-privatesise-text161">
                    Usage Data
                  </span>
                  <span className="politikat-e-privatesise-text162">
                    {' '}
                    refers to data collected automatically, either generated by
                    the use of the Service or from the Service infrastructure
                    itself (for example, the duration of a page visit).
                  </span>
                  <br className="politikat-e-privatesise-text163"></br>
                  <br className="politikat-e-privatesise-text164"></br>
                  <span className="politikat-e-privatesise-text165">
                    You
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="politikat-e-privatesise-text166">
                    means the individual accessing or using the Service, or the
                    company, or other legal entity on behalf of which such
                    individual is accessing or using the Service, as applicable.
                  </span>
                  <br className="politikat-e-privatesise-text167"></br>
                  <br className="politikat-e-privatesise-text168"></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading3 thq-heading-3">
                  Collecting and Using Your Personal Data
                </h3>
                <p className="politikat-e-privatesise-content31 thq-body-small">
                  <span className="politikat-e-privatesise-text169">
                    Types of Data Collected
                  </span>
                  <br className="politikat-e-privatesise-text170"></br>
                  <br className="politikat-e-privatesise-text171"></br>
                  <br className="politikat-e-privatesise-text172"></br>
                  <span className="politikat-e-privatesise-text173">
                    Personal Data
                  </span>
                  <br className="politikat-e-privatesise-text174"></br>
                  <br className="politikat-e-privatesise-text175"></br>
                  <span className="politikat-e-privatesise-text176">
                    While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You. Personally identifiable
                    information may include, but is not limited to:
                  </span>
                  <br></br>
                  <br className="politikat-e-privatesise-text178"></br>
                  <span className="politikat-e-privatesise-text179">
                    Email address
                  </span>
                  <br></br>
                  <br className="politikat-e-privatesise-text181"></br>
                  <span className="politikat-e-privatesise-text182">
                    Usage Data
                  </span>
                  <br className="politikat-e-privatesise-text183"></br>
                </p>
                <p className="politikat-e-privatesise-content32 thq-body-small">
                  <br></br>
                  <span className="politikat-e-privatesise-text185">
                    Usage Data
                  </span>
                  <br className="politikat-e-privatesise-text186"></br>
                  <br className="politikat-e-privatesise-text187"></br>
                  <span>
                    Usage Data is collected automatically when using the
                    Service.
                  </span>
                  <br className="politikat-e-privatesise-text189"></br>
                  <br className="politikat-e-privatesise-text190"></br>
                  <span>
                    Usage Data may include information such as Your
                    Device&apos;s Internet Protocol address (e.g. IP address),
                    browser type, browser version, the pages of our Service that
                    You visit, the time and date of Your visit, the time spent
                    on those pages, unique device identifiers and other
                    diagnostic data.
                  </span>
                  <br className="politikat-e-privatesise-text192"></br>
                  <br className="politikat-e-privatesise-text193"></br>
                  <span>
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.
                  </span>
                  <br className="politikat-e-privatesise-text195"></br>
                  <br className="politikat-e-privatesise-text196"></br>
                  <span>
                    We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.
                  </span>
                  <br className="politikat-e-privatesise-text198"></br>
                  <br className="politikat-e-privatesise-text199"></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading4 thq-heading-3">
                  Information Collected while Using the Application
                </h3>
                <p className="politikat-e-privatesise-content4 thq-body-small">
                  <span>
                    While using Our Application, in order to provide features of
                    Our Application, We may collect, with Your prior permission:
                  </span>
                  <br className="politikat-e-privatesise-text201"></br>
                  <br></br>
                  <span>● Information regarding your location</span>
                  <br className="politikat-e-privatesise-text204"></br>
                  <br></br>
                  <span>
                    ● Pictures and other information from your Device&apos;s
                    camera and photo library
                  </span>
                  <br className="politikat-e-privatesise-text207"></br>
                  <br></br>
                  <span>
                    We use this information to provide features of Our Service,
                    to improve and customize Our Service. The information may be
                    uploaded to the Company&apos;s servers and/or a Service
                    Provider&apos;s server or it may be simply stored on Your
                    device.
                  </span>
                  <br className="politikat-e-privatesise-text210"></br>
                  <br></br>
                  <span>
                    You can enable or disable access to this information at any
                    time, through Your Device settings.
                  </span>
                  <br className="politikat-e-privatesise-text213"></br>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading5 thq-heading-3">
                  Use of Your Personal Data
                </h3>
                <p className="politikat-e-privatesise-content5 thq-body-small">
                  <span className="politikat-e-privatesise-text215">
                    The Company may use Personal Data for the following
                    purposes:
                  </span>
                  <br className="politikat-e-privatesise-text216"></br>
                  <br className="politikat-e-privatesise-text217"></br>
                  <br className="politikat-e-privatesise-text218"></br>
                  <span className="politikat-e-privatesise-text219">
                    To provide and maintain our Service
                  </span>
                  <span className="politikat-e-privatesise-text220">
                    , including to monitor the usage of our Service.
                  </span>
                  <br className="politikat-e-privatesise-text221"></br>
                  <br className="politikat-e-privatesise-text222"></br>
                  <span className="politikat-e-privatesise-text223">
                    To manage Your Account
                  </span>
                  <span className="politikat-e-privatesise-text224">
                    : to manage Your registration as a user of the Service. The
                    Personal Data You provide can give You access to different
                    functionalities of the Service that are available to You as
                    a registered user.
                  </span>
                  <br className="politikat-e-privatesise-text225"></br>
                  <br className="politikat-e-privatesise-text226"></br>
                  <span className="politikat-e-privatesise-text227"></span>
                  <span className="politikat-e-privatesise-text228">
                    For the performance of a contract
                  </span>
                  <span className="politikat-e-privatesise-text229">
                    : the development, compliance and undertaking of the
                    purchase contract for the products, items or services You
                    have purchased or of any other contract with Us through the
                    Service.
                  </span>
                  <br className="politikat-e-privatesise-text230"></br>
                  <br className="politikat-e-privatesise-text231"></br>
                  <span className="politikat-e-privatesise-text232">
                    To contact You:
                  </span>
                  <span className="politikat-e-privatesise-text233">
                    {' '}
                    To contact You by email, telephone calls, SMS, or other
                    equivalent forms of electronic communication, such as a
                    mobile application&apos;s push notifications regarding
                    updates or informative communications related to 
                  </span>
                  <span className="politikat-e-privatesise-text234">
                    the functionalities, products or contracted services,
                    including the security updates, when necessary or reasonable
                    for their implementation.
                  </span>
                  <br className="politikat-e-privatesise-text235"></br>
                  <br className="politikat-e-privatesise-text236"></br>
                  <span className="politikat-e-privatesise-text237">
                    To provide You
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="politikat-e-privatesise-text238">
                    with news, special offers and general information about
                    other goods, services and events which we offer that are
                    similar to those that you have already purchased or enquired
                    about unless You have opted not to receive such information.
                  </span>
                  <br className="politikat-e-privatesise-text239"></br>
                  <br className="politikat-e-privatesise-text240"></br>
                  <span className="politikat-e-privatesise-text241">
                    To manage Your requests:
                  </span>
                  <span className="politikat-e-privatesise-text242">
                    {' '}
                    To attend and manage Your requests to Us.
                  </span>
                  <br className="politikat-e-privatesise-text243"></br>
                  <br className="politikat-e-privatesise-text244"></br>
                  <span className="politikat-e-privatesise-text245">
                    For business transfers:
                  </span>
                  <span className="politikat-e-privatesise-text246">
                    {' '}
                    We may use Your information to evaluate or conduct a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Our assets, whether
                    as a going concern or as part of bankruptcy, liquidation, or
                    similar proceeding, in which Personal Data held by Us about
                    our Service users is among the assets transferred.
                  </span>
                  <br className="politikat-e-privatesise-text247"></br>
                  <br className="politikat-e-privatesise-text248"></br>
                  <span className="politikat-e-privatesise-text249">
                    For other purposes:
                  </span>
                  <span className="politikat-e-privatesise-text250">
                    {' '}
                    We may use Your information for other purposes, such as data
                    analysis, identifying usage trends, determining the
                    effectiveness of our 
                  </span>
                  <span className="politikat-e-privatesise-text251">
                    promotional campaigns and to evaluate and improve our
                    Service, products, services, marketing and your experience.
                  </span>
                  <br className="politikat-e-privatesise-text252"></br>
                  <br className="politikat-e-privatesise-text253"></br>
                  <br className="politikat-e-privatesise-text254"></br>
                  <span className="politikat-e-privatesise-text255">
                    We may share Your personal information in the following
                    situations:
                  </span>
                  <br className="politikat-e-privatesise-text256"></br>
                  <br className="politikat-e-privatesise-text257"></br>
                  <br className="politikat-e-privatesise-text258"></br>
                  <span className="politikat-e-privatesise-text259">
                    With Service Providers:
                  </span>
                  <span className="politikat-e-privatesise-text260">
                    {' '}
                    We may share Your personal information with Service
                    Providers to monitor and analyze the use of our Service, to
                    contact You.
                  </span>
                  <br className="politikat-e-privatesise-text261"></br>
                  <br className="politikat-e-privatesise-text262"></br>
                  <span className="politikat-e-privatesise-text263">
                    For business transfers:
                  </span>
                  <span className="politikat-e-privatesise-text264">
                    {' '}
                    We may share or transfer Your personal information in
                    connection with, or during negotiations of, any merger, sale
                    of Company assets, financing, or acquisition of all or a
                    portion of Our business to another company.
                  </span>
                  <br className="politikat-e-privatesise-text265"></br>
                  <br className="politikat-e-privatesise-text266"></br>
                  <span className="politikat-e-privatesise-text267">
                    With Affiliates:
                  </span>
                  <span className="politikat-e-privatesise-text268">
                    {' '}
                    We may share Your information with Our affiliates, in which
                    case we will require those affiliates to honor this Privacy
                    Policy. Affiliates include Our parent company and any other
                    subsidiaries, joint venture partners or other companies that
                    We control or that are under common control with Us.
                  </span>
                  <br className="politikat-e-privatesise-text269"></br>
                  <br className="politikat-e-privatesise-text270"></br>
                  <span className="politikat-e-privatesise-text271">
                    With business partners:
                  </span>
                  <span className="politikat-e-privatesise-text272">
                    {' '}
                    We may share Your information with Our business partners to
                    offer You certain products, services or promotions.
                  </span>
                  <br className="politikat-e-privatesise-text273"></br>
                  <br className="politikat-e-privatesise-text274"></br>
                  <span className="politikat-e-privatesise-text275">
                    With other users:
                  </span>
                  <span className="politikat-e-privatesise-text276">
                    {' '}
                    when You share personal information or otherwise interact in
                    the public areas with other users, such information may be
                    viewed by all users and may be publicly distributed outside.
                  </span>
                  <br className="politikat-e-privatesise-text277"></br>
                  <br className="politikat-e-privatesise-text278"></br>
                  <span className="politikat-e-privatesise-text279">
                    With Your consent:
                  </span>
                  <span className="politikat-e-privatesise-text280">
                    {' '}
                    We may disclose Your personal information for any other
                    purpose with Your consent.
                  </span>
                  <br className="politikat-e-privatesise-text281"></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading61 thq-heading-3">
                  Retention of Your Personal Data
                </h3>
                <p className="politikat-e-privatesise-content61 thq-body-small">
                  <span>
                    The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.
                  </span>
                  <br className="politikat-e-privatesise-text283"></br>
                  <br className="politikat-e-privatesise-text284"></br>
                  <span>
                    The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading62 thq-heading-3">
                  Transfer of Your Personal Data
                </h3>
                <p className="politikat-e-privatesise-content62 thq-body-small">
                  <span>
                    Your information, including Personal Data, is processed at
                    the Company&apos;s operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to — and
                    maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from Your
                    jurisdiction.
                  </span>
                  <br className="politikat-e-privatesise-text288"></br>
                  <br className="politikat-e-privatesise-text289"></br>
                  <span>
                    Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.
                  </span>
                  <br className="politikat-e-privatesise-text291"></br>
                  <br className="politikat-e-privatesise-text292"></br>
                  <span>
                    The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your 
                  </span>
                  <span>
                    Personal Data will take place to an organization or a
                    country unless there are adequate controls in place
                    including the security of Your data and other personal
                    information.
                  </span>
                  <br className="politikat-e-privatesise-text295"></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading63 thq-heading-3">
                  Delete Your Personal Data
                </h3>
                <p className="politikat-e-privatesise-content63 thq-body-small">
                  <span>
                    You have the right to delete or request that We assist in
                    deleting the Personal Data that We have collected about You.
                  </span>
                  <br className="politikat-e-privatesise-text297"></br>
                  <br></br>
                  <span>
                    Our Service may give You the ability to delete certain
                    information about You from within the Service.
                  </span>
                  <br className="politikat-e-privatesise-text300"></br>
                  <br></br>
                  <span>
                    You may update, amend, or delete Your information at any
                    time by signing in to Your Account, if you have one, and
                    visiting the account settings section that allows you to
                    manage Your personal information. You may also contact Us to
                    request access to, correct, or delete any personal
                    information that You have provided to Us.
                  </span>
                  <br className="politikat-e-privatesise-text303"></br>
                  <br></br>
                  <span>
                    {' '}
                    Please note, however, that We may need to retain certain
                    information when we have a legal obligation or lawful basis
                    to do so.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading64 thq-heading-3">
                  Disclosure of Your Personal Data
                </h3>
                <p className="politikat-e-privatesise-content64 thq-body-small">
                  <span className="politikat-e-privatesise-text307">
                    Business Transactions
                  </span>
                  <br className="politikat-e-privatesise-text308"></br>
                  <br className="politikat-e-privatesise-text309"></br>
                  <span className="politikat-e-privatesise-text310">
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                  </span>
                  <br className="politikat-e-privatesise-text311"></br>
                  <br className="politikat-e-privatesise-text312"></br>
                  <span className="politikat-e-privatesise-text313">
                    Law enforcement
                  </span>
                  <br className="politikat-e-privatesise-text314"></br>
                  <br className="politikat-e-privatesise-text315"></br>
                  <span className="politikat-e-privatesise-text316">
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                  </span>
                  <br className="politikat-e-privatesise-text317"></br>
                  <br className="politikat-e-privatesise-text318"></br>
                  <span className="politikat-e-privatesise-text319">
                    Other legal requirements
                  </span>
                  <br className="politikat-e-privatesise-text320"></br>
                  <br className="politikat-e-privatesise-text321"></br>
                  <span className="politikat-e-privatesise-text322">
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                  </span>
                  <br className="politikat-e-privatesise-text323"></br>
                  <br className="politikat-e-privatesise-text324"></br>
                  <span className="politikat-e-privatesise-text325">
                    Comply with a legal obligation
                  </span>
                  <br className="politikat-e-privatesise-text326"></br>
                  <br className="politikat-e-privatesise-text327"></br>
                  <span className="politikat-e-privatesise-text328">
                    Protect and defend the rights or property of the Company
                  </span>
                  <br className="politikat-e-privatesise-text329"></br>
                  <br className="politikat-e-privatesise-text330"></br>
                  <span className="politikat-e-privatesise-text331">
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </span>
                  <br className="politikat-e-privatesise-text332"></br>
                  <br className="politikat-e-privatesise-text333"></br>
                  <span className="politikat-e-privatesise-text334">
                    Protect the personal safety of Users of the Service or the
                    public
                  </span>
                  <br className="politikat-e-privatesise-text335"></br>
                  <br className="politikat-e-privatesise-text336"></br>
                  <span className="politikat-e-privatesise-text337">
                    Protect against legal liability
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading65 thq-heading-3">
                  Security of Your Personal Data
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </h3>
                <p className="politikat-e-privatesise-content65 thq-body-small">
                  The security of Your Personal Data is important to Us, but
                  remember that no method of transmission over the Internet, or
                  method of electronic storage is 100% secure. While We strive
                  to use commercially acceptable means to protect Your Personal
                  Data, We cannot guarantee its absolute security.
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading66 thq-heading-3">
                  Children&apos;s Privacy
                </h3>
                <p className="politikat-e-privatesise-content66 thq-body-small">
                  <span>
                    Our Service does not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from anyone under the age of 13. If You are a parent or
                    guardian and You are aware that Your child has provided Us
                    with Personal Data, please contact Us. If We become aware
                    that We have collected Personal Data from anyone under the
                    age of 13 without verification of parental consent, We take
                    steps to remove that information from Our servers.
                  </span>
                  <br className="politikat-e-privatesise-text340"></br>
                  <br className="politikat-e-privatesise-text341"></br>
                  <span>
                    If We need to rely on consent as a legal basis for
                    processing Your information and Your country requires
                    consent from a parent, We may require Your parent&apos;s
                    consent before We collect and use that information.
                  </span>
                  <br className="politikat-e-privatesise-text343"></br>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading67 thq-heading-3">
                  Links to Other Websites
                </h3>
                <p className="politikat-e-privatesise-content67 thq-body-small">
                  <span>
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party&apos;s site. We strongly
                    advise You to review the Privacy Policy of every site You
                    visit.
                  </span>
                  <br className="politikat-e-privatesise-text346"></br>
                  <br></br>
                  <span>
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading68 thq-heading-3">
                  Changes to this Privacy Policy
                </h3>
                <p className="politikat-e-privatesise-content68 thq-body-small">
                  <span>
                    We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                  </span>
                  <br className="politikat-e-privatesise-text351"></br>
                  <br className="politikat-e-privatesise-text352"></br>
                  <span>
                    We will let You know via email and/or a prominent notice on
                    Our Service, prior to the change becoming effective and
                    update the &quot;Last updated&quot; date at the top of this
                    Privacy Policy.
                  </span>
                  <br className="politikat-e-privatesise-text354"></br>
                  <br className="politikat-e-privatesise-text355"></br>
                  <span>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="politikat-e-privatesise-heading69 thq-heading-3">
                  Contact Us
                </h3>
                <p className="politikat-e-privatesise-content69 thq-body-small">
                  <span>
                    If you have any questions about this Privacy Policy, You can
                    contact us:
                  </span>
                  <br className="politikat-e-privatesise-text359"></br>
                  <br></br>
                  <span>● By email: office@icharge.app</span>
                  <br className="politikat-e-privatesise-text362"></br>
                  <br></br>
                </p>
              </li>
              <li className="list-item"></li>
            </ul>
          </div>
        </div>
      </div>
      <div id="footer" className="politikat-e-privatesise-footer">
        <div className="politikat-e-privatesise-content8">
          <div className="politikat-e-privatesise-information">
            <div className="politikat-e-privatesise-heading">
              <a href="##content" className="politikat-e-privatesise-link15">
                <img
                  alt="pastedImage"
                  src="/logo_icharge_primary_page-0002-removebg-preview-200h-200h-200h.png"
                  loading="lazy"
                  className="politikat-e-privatesise-pasted-image1"
                />
              </a>
              <span className="politikat-e-privatesise-text364">
                <span>Adresa: Rruga e Kavajes,</span>
                <br></br>
                <span>km i 1, Godina ne krahe te Vetingut.</span>
              </span>
            </div>
            <div className="politikat-e-privatesise-socials">
              <a
                href="https://www.instagram.com/icharge.alb/"
                target="_blank"
                rel="noreferrer noopener"
                className="politikat-e-privatesise-link16"
              >
                <div className="politikat-e-privatesise-social1">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="politikat-e-privatesise-icon14"
                  >
                    <circle
                      r="1.44"
                      cx="22.406"
                      cy="9.594"
                      fill="currentColor"
                    ></circle>
                    <path
                      d="M16 9.838A6.162 6.162 0 1 0 22.162 16A6.16 6.16 0 0 0 16 9.838M16 20a4 4 0 1 1 4-4a4 4 0 0 1-4 4"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M16 6.162c3.204 0 3.584.012 4.849.07a6.6 6.6 0 0 1 2.228.413a3.98 3.98 0 0 1 2.278 2.278a6.6 6.6 0 0 1 .413 2.228c.058 1.265.07 1.645.07 4.85s-.012 3.583-.07 4.848a6.6 6.6 0 0 1-.413 2.228a3.98 3.98 0 0 1-2.278 2.278a6.6 6.6 0 0 1-2.228.413c-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07a6.6 6.6 0 0 1-2.228-.413a3.98 3.98 0 0 1-2.278-2.278a6.6 6.6 0 0 1-.413-2.228c-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849a6.6 6.6 0 0 1 .413-2.228a3.98 3.98 0 0 1 2.278-2.278a6.6 6.6 0 0 1 2.228-.413c1.265-.058 1.645-.07 4.849-.07M16 4c-3.259 0-3.668.014-4.948.072a8.8 8.8 0 0 0-2.912.558a6.14 6.14 0 0 0-3.51 3.51a8.8 8.8 0 0 0-.558 2.913C4.014 12.333 4 12.74 4 16s.014 3.668.072 4.948a8.8 8.8 0 0 0 .558 2.912a6.14 6.14 0 0 0 3.51 3.51a8.8 8.8 0 0 0 2.913.558c1.28.058 1.688.072 4.947.072s3.668-.014 4.948-.072a8.8 8.8 0 0 0 2.913-.558a6.14 6.14 0 0 0 3.51-3.51a8.8 8.8 0 0 0 .557-2.913C27.986 19.667 28 19.26 28 16s-.014-3.668-.072-4.948a8.8 8.8 0 0 0-.558-2.912a6.14 6.14 0 0 0-3.51-3.51a8.8 8.8 0 0 0-2.913-.557C19.667 4.013 19.26 4 16 4"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.tiktok.com/@icharge.app"
                target="_blank"
                rel="noreferrer noopener"
                className="politikat-e-privatesise-link17"
              >
                <div className="politikat-e-privatesise-social2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="politikat-e-privatesise-icon18"
                  >
                    <path
                      d="M16.6 5.82s.51.5 0 0A4.28 4.28 0 0 1 15.54 3h-3.09v12.4a2.59 2.59 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.linkedin.com/feed/"
                target="_blank"
                rel="noreferrer noopener"
                className="politikat-e-privatesise-link18"
              >
                <div className="politikat-e-privatesise-social3">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="politikat-e-privatesise-icon20"
                  >
                    <g fill="currentColor">
                      <path
                        d="M12.51 8.796v1.697a3.74 3.74 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766c-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483a1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.6 1.6 0 0 1 1.6 1.606"
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                      ></path>
                      <path d="M7.2 8.809H4V19.5h3.2z"></path>
                    </g>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.facebook.com/icharge.alb"
                target="_blank"
                rel="noreferrer noopener"
                className="politikat-e-privatesise-link19"
              >
                <div className="politikat-e-privatesise-social4">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="politikat-e-privatesise-icon24"
                  >
                    <path
                      d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6z"
                      fill="currentColor"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
          <div className="politikat-e-privatesise-links2">
            <div className="politikat-e-privatesise-column1">
              <span className="politikat-e-privatesise-header2">
                iCharge.app
              </span>
              <Link to="/" className="politikat-e-privatesise-link20">
                Home
              </Link>
              <Link to="/" className="politikat-e-privatesise-link21">
                Rreth nesh
              </Link>
              <a
                href="https://m.icharge.app/#/homepage"
                target="_blank"
                rel="noreferrer noopener"
                className="politikat-e-privatesise-link22"
              >
                Pika Karikimi
              </a>
              <Link
                to="/kushtet-e-perdorimit"
                className="politikat-e-privatesise-link23"
              >
                Kushtet e perdorimit
              </Link>
              <Link
                to="/politikat-e-privatesise"
                className="politikat-e-privatesise-link24"
              >
                Politikat e privatesise
              </Link>
            </div>
            <div className="politikat-e-privatesise-column2">
              <span className="politikat-e-privatesise-header3">Kontakt</span>
              <a
                href="tel:+355694080227"
                className="politikat-e-privatesise-link25"
              >
                Tel: +355694080227
              </a>
              <a
                href="mailto:office@icharge.app?subject="
                className="politikat-e-privatesise-link26"
              >
                Email: office@icharge.app
              </a>
            </div>
          </div>
        </div>
        <div className="politikat-e-privatesise-pills">
          <div className="politikat-e-privatesise-container4">
            <div className="politikat-e-privatesise-you-tube1">
              <a
                href="https://apps.apple.com/lv/app/icharge-app/id6479523452"
                target="_blank"
                rel="noreferrer noopener"
                className="politikat-e-privatesise-link27"
              >
                <img
                  alt="pastedImage"
                  src="/Icons/download%20(3)-600w.png"
                  className="politikat-e-privatesise-pasted-image2"
                />
              </a>
            </div>
            <div className="politikat-e-privatesise-you-tube2">
              <a
                href="https://play.google.com/store/apps/details?id=com.icharge.mobile.app"
                target="_blank"
                rel="noreferrer noopener"
                className="politikat-e-privatesise-link28"
              >
                <img
                  alt="pastedImage"
                  src="/Icons/download%20(4)-200h.png"
                  className="politikat-e-privatesise-pasted-image3"
                />
              </a>
            </div>
          </div>
        </div>
        <span className="politikat-e-privatesise-text368">
          © 2024 iCharge. All Rights Reserved.
        </span>
      </div>
    </div>
  )
}

export default PolitikatEPrivatesise
