import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './kushtet-e-perdorimit.css'

const KushtetEPerdorimit = (props) => {
  return (
    <div className="kushtet-e-perdorimit-container1">
      <Helmet>
        <title>
          Kushtet-e-perdorimit - iCharge.app - Stacione dhe pika karikimi për
          makina elektrike.
        </title>
        <meta
          name="description"
          content="iCharge.app: Zgjidhja inovative për makina elektrike. Zbuloni stacionet dhe pikat më të mira të karikimit me nje sherbim superior ne krahasim me Vega Group."
        />
        <meta
          property="og:title"
          content="Kushtet-e-perdorimit - iCharge.app - Stacione dhe pika karikimi për makina elektrike."
        />
        <meta
          property="og:description"
          content="Zbuloni iCharge.app, platforma juaj për karikimin e makinave elektrike në Shqipëri. "
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d334a72c-d322-4e02-af2f-aa1a000a9ada/f77c7958-1225-4afe-a283-098a54e58587?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <header className="kushtet-e-perdorimit-header1">
        <header data-thq="thq-navbar" className="kushtet-e-perdorimit-navbar">
          <div className="kushtet-e-perdorimit-left">
            <Link to="/" className="kushtet-e-perdorimit-navlink1">
              <img
                alt="image"
                src="/logo_icharge_primary_1%20(1)-200h.png"
                loading="eager"
                className="kushtet-e-perdorimit-logo"
              />
            </Link>
          </div>
          <div
            data-thq="thq-navbar-btn-group"
            className="kushtet-e-perdorimit-right"
          ></div>
          <div
            data-thq="thq-burger-menu"
            className="kushtet-e-perdorimit-burger-menu"
          >
            <svg
              viewBox="0 0 1024 1024"
              className="kushtet-e-perdorimit-icon10"
            >
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="kushtet-e-perdorimit-mobile-menu"
          >
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="kushtet-e-perdorimit-nav1"
            >
              <div className="kushtet-e-perdorimit-container2">
                <Link to="/" className="kushtet-e-perdorimit-navlink2">
                  <img
                    alt="image"
                    src="/logo_icharge_primary_1%20(1)-200h.png"
                    className="kushtet-e-perdorimit-image"
                  />
                </Link>
                <div
                  data-thq="thq-close-menu"
                  className="kushtet-e-perdorimit-menu-close"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="kushtet-e-perdorimit-icon12"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="kushtet-e-perdorimit-nav2"
              >
                <Link to="/" className="kushtet-e-perdorimit-navlink3">
                  Home
                </Link>
                <Link to="/" className="kushtet-e-perdorimit-navlink4">
                  Rreth Nesh
                </Link>
                <a
                  href="https://m.icharge.app/#/homepage"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="kushtet-e-perdorimit-link10"
                >
                  Pike Karikimi
                </a>
                <Link to="/" className="kushtet-e-perdorimit-navlink5">
                  Kontakt
                </Link>
              </nav>
            </div>
          </div>
          <nav className="kushtet-e-perdorimit-links1">
            <Link to="/" className="kushtet-e-perdorimit-link11">
              Home
            </Link>
            <Link to="/" className="kushtet-e-perdorimit-link12">
              Rreth Nesh
            </Link>
            <a
              href="https://m.icharge.app/#/homepage"
              target="_blank"
              rel="noreferrer noopener"
              className="kushtet-e-perdorimit-link13"
            >
              Pika Karikimi
            </a>
            <a href="#footer" className="kushtet-e-perdorimit-link14">
              Kontakt
            </a>
          </nav>
        </header>
      </header>
      <div className="kushtet-e-perdorimit-container3 thq-section-padding">
        <div className="kushtet-e-perdorimit-max-width thq-section-max-width">
          <div className="kushtet-e-perdorimit-content7 thq-flex-column">
            <ul className="kushtet-e-perdorimit-ul thq-flex-column">
              <li className="thq-flex-column list-item">
                <h2 className="kushtet-e-perdorimit-heading1 thq-heading-2">
                  Terms and Conditions
                </h2>
                <h3 className="kushtet-e-perdorimit-heading21 thq-heading-3">
                  1. Your acceptance
                </h3>
                <p className="kushtet-e-perdorimit-content1 thq-body-small">
                  <span>
                    1.1 These are the terms and conditions “Terms” on which
                    Icharge (referred to as “Service Provider”, permits users
                    (referred to as “Client”) to:
                  </span>
                  <br className="kushtet-e-perdorimit-text101"></br>
                  <br></br>
                  <span>
                    (a) use the Icharge open-platform network of electric
                    vehicle charging stations (Charging Stations), including all
                    electric vehicle service equipment (EVSE), the electric
                    vehicle charging applications it delivers, and all support
                    network infrastructure and services supplied by Icharge in
                    respect of the Charging Stations (Icharge Network); and
                  </span>
                  <br className="kushtet-e-perdorimit-text104"></br>
                  <br></br>
                  <span>
                    (b) access and use the Icharge apps which provide data in
                    respect of the Icharge Network (Icharge App) and the Icharge
                    website, located at icharge.com (Website).
                  </span>
                  <br className="kushtet-e-perdorimit-text107"></br>
                  <br></br>
                  <span>
                    1.2 You agree to be bound by these Terms when you:
                  </span>
                  <br className="kushtet-e-perdorimit-text110"></br>
                  <br></br>
                  <span>
                    (a) apply for a subscription or register an account to use
                    the Icharge Network;
                  </span>
                  <br className="kushtet-e-perdorimit-text113"></br>
                  <br></br>
                  <span>
                    (b) use any piece of EVSE located at a Charging Station; or
                  </span>
                  <br className="kushtet-e-perdorimit-text116"></br>
                  <br></br>
                  <span>
                    (c) use, browse or access any part of the Icharge App or
                    Website.
                  </span>
                  <br className="kushtet-e-perdorimit-text119"></br>
                  <br></br>
                  <span>
                    1.3 If you access a Charging Station using an electric
                    vehicle (EV) which does not belong to you, the act of doing
                    so will also bind the owner of the EV you are driving and
                    warrant your authority to do so.
                  </span>
                  <br className="kushtet-e-perdorimit-text122"></br>
                  <br></br>
                  <span>
                    1.4 Icharge may from time to time review and update these
                    Terms and the Icharge Privacy Policy (a copy of which can be
                    found on the Website) to take account of new laws,
                    regulations, products, technology or other relevant changes
                    in circumstances. Your use of the Icharge Network, Icharge
                    App and Website will be governed by the most recent Terms
                    posted on the Website. By continuing to use the Icharge
                    Network, Icharge App or Website, you agree to be bound by
                    the most recent Terms. It is your responsibility to check
                    the Website regularly for updated versions of the Terms.
                  </span>
                  <br className="kushtet-e-perdorimit-text125"></br>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading22 thq-heading-3">
                  2. Registering an Account
                </h3>
                <p className="kushtet-e-perdorimit-content2 thq-body-small">
                  <span>
                    2.1 You may be required to register an account in order to
                    use the Icharge Network (Account).
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    2.2 You warrant that all information and data provided by
                    you in the process of registering an Account is accurate,
                    complete and up to date. You will promptly inform Icharge if
                    there is any subsequent change to your information or data.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    2.3 By registering an Account, you warrant to us that:
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (a) you are authorised and have full power and capacity to
                    register and control all EVs associated with your Account
                    and will notify Icharge, through the Icharge App or using
                    the contact details outlined in clause 18,
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (b) you are at least 16 years of age and possess the legal
                    authority to enter into and use the Icharge Network, the
                    Icharge App and the Website in accordance with these Terms
                    and the law.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    2.4 You agree to be financially responsible for all use of
                    the Icharge Network (as well as for use by others of the
                    Icharge Network to charge your EV), and to pay all
                    applicable Fees (as defined in clause 2.5) for access to a
                    Charging Station initiated by you or attributed to an EV or
                    Icharge RFID card (RFID Card) which is associated with your
                    Account.
                  </span>
                  <br></br>
                  <br></br>
                  <span>In particular, you agree to:</span>
                  <br></br>
                  <br></br>
                  <span>
                    (a) promptly review your payment method statement (such as
                    your credit card statement) and notify Icharge customer
                    service in writing of any questions regarding Fees. Fees not
                    questioned by this method within 30 days of the applicable
                    statement date will be deemed valid; and
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (b) promptly update your Account information with any
                    changes to your name, email address, mailing address,
                    telephone number and any applicable credit card information.
                    Account information can be updated through the Icharge App
                    or the Website (if applicable).
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    2.5 For the purposes of these Terms, the amount payable by
                    you for your use of the Icharge Network includes:
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (a) your usage fee, being the kWh rate advertised with
                    respect to each Charging Station plus any applicable taxes;
                    and
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (b) any applicable Idle Fees (as defined in clause 5.2)
                    charged in accordance with these Terms, (collectively, the
                    “Fees”).
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    2.5 In the event that payment on your credit card or other
                    payment method is declined you must rectify this immediately
                    and failure to do so may result in Icharge terminating your
                    Account. In the event Icharge terminates your Account,
                    Icharge may institute collection proceedings in order to
                    collect any unpaid balance on your Account and any fees,
                    costs or other expenses incurred by Icharge in connection
                    with its collection efforts.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    2.6 In the event that your RFID Card is lost, stolen or
                    damaged, you must promptly notify Icharge in accordance with
                    the contact details provided in clause 18. Icharge will,
                    within seven
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (7) days, remove the RFID Card from your Account (Notice
                    Period). You agree that any usage associated with your RFID
                    Card during the Notice Period remains a Fee payable by you.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    2.7 You agree to not attempt to scrape, decompile,
                    data-mine, reverse engineer, disassemble, or otherwise deal
                    with the Icharge App, Website, Icharge Network or any
                    Charging Stations or EVSE, in any way other than as it is
                    intended to be used and as is permitted by these Terms.
                  </span>
                  <br></br>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading3 thq-heading-3">
                  3. Provision of Charging Stations
                </h3>
                <p className="kushtet-e-perdorimit-content3 thq-body-small">
                  <span>
                    3.1 Icharge provides access to Charging Stations which are
                    owned and maintained by third parties. Nothing in these
                    Terms or otherwise inferred by your use of the Charging
                    Stations places any obligation on Icharge to maintain
                    Charging Stations. Such obligation is borne solely by the
                    third-party charge point operator (CPO) who owns the
                    Charging Stations (subject to any relevant third-party CPO
                    terms and conditions as detailed under clause 7).
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    3.2 Icharge may, from time to time, make available the
                    Charging Stations for use by Account holders to charge their
                    EV. Icharge reserves the right, at its discretion (acting
                    reasonably), to refuse or suspend access to any or all
                    Charging Stations to any person (including but not limited
                    to any person found to be in breach of these Terms).
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    3.3 You acknowledge that in the event Icharge refuses or
                    suspends access to any or all Charging Stations, Icharge
                    will (if practicable) generally attempt to provide notice to
                    the contact details associated with an Account, however, in
                    certain circumstances it is not practicable for Icharge to
                    provide notice. In such circumstances, Icharge reserves the
                    right to not provide notice.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    3.4 Icharge may refuse or suspend the use of the Icharge
                    Network, or any Charging Station or EVSE, at any time and
                    will endeavour to update the Icharge App or Website, in the
                    event:
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (a) one or more piece of EVSE requires maintenance or
                    upgrading; or
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (b) a provision of the Icharge Network is disrupted or
                    prevented as a result of an event outside Icharge’s
                    reasonable control or for safety reasons, (being Service
                    Interruptions), however Icharge does not guarantee that
                    prior notice of Service Interruptions will be provided to
                    you.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    3.5 Icharge may refuse or suspend the use of the Icharge
                    Network, or any Charging Station or EVSE, at any time and
                    without notice to you, if we have a reasonable belief that
                    the Icharge Network or a Charging Station is being used
                    unethically, unlawfully or contrary to any law or
                    regulation.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    3.6 Icharge does not guarantee, and is under no obligation
                    to ensure, the availability, compatibility with your EV, or
                    performance of Charging Stations, nor does it promise to
                    provide an uninterrupted electricity supply to Charging
                    Stations. Icharge cannot guarantee that connecting your EV
                    to one of our Charging Stations will result in a successful
                    and timely recharge of your EV’s battery or batteries.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    3.7 If there are any faults or you have any issues with any
                    Charging Station, please contact us as soon as possible
                    using the contact details outlined in clause 18.
                  </span>
                  <br></br>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading4 thq-heading-3">
                  4. Use of Charging Stations and fair use
                </h3>
                <p className="kushtet-e-perdorimit-content4 thq-body-small">
                  <span>
                    4.1 Your use of the Charging Stations and any prepaid
                    charging packs, e-vouchers, gift cards (if applicable) must
                    be fair, reasonable, not excessive and in an acceptable
                    manner. If we consider, in our reasonable discretion, your
                    usage to be unfair, unreasonable, excessive or in an
                    unacceptable manner (collectively, Unreasonable Conduct),
                    without limiting clause 3 above, we may immediately suspend,
                    terminate, modify or restrict your access to any or all
                    Charging Stations.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    We may consider your use of the Charging Stations to be
                    Unreasonable Conduct if you use Charging Stations in a
                    manner other than they are intended for. Below are some
                    examples of uses that we consider Unreasonable Conduct. This
                    is not intended to be an exhaustive list:
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (a) using or accessing the Charging Stations in a manner
                    that breaches these Terms or any applicable laws and
                    regulations;
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (b) using the Charging Stations for any fraudulent purpose;
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (c) in the case of Icharge’ subscription charging service,
                    using your Account to provide anyone other than yourself,
                    access to free charging on the Icharge Network; or
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (d) using the Charging Stations and Icharge Network in a
                    manner that could interfere with the service, our networks,
                    the assets and equipment of us or a CPO or our ability to
                    provide services to you or another person.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    4.2 You agree not to use, or attempt to use, Charging
                    Stations for any purpose other than to charge your EV and
                    then only to the extent that your EV is compatible with the
                    Charging Stations.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    4.3 When using the Charging Stations, you agree to comply
                    with all applicable laws and regulations. In particular, you
                    agree to:
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (a) take reasonable care for your own health and safety;
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (b) take reasonable care that your acts or omissions do not
                    adversely affect the health and safety of others;
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (c) comply, as far as you are reasonably able to, with any
                    reasonable instruction that is given by Icharge or the
                    relevant CPO. This means complying with the relevant EV
                    manufacturer’s handbook and any instructions for use set out
                    at the Charging Stations (which may be updated by Icharge or
                    a CPO from time to time and so should be reviewed by you
                    each time you use a Charging Station); and
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (d) be aware of any parking restrictions which may be being
                    enforced nearby or attached to the Charging Station.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    4.4 When using the Charging Stations, you must ensure that
                    the EVSE located at that Charging Station is compatible with
                    your EV,
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (a) including that the charging cable is suitable for use on
                    your EV.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (b) check the app and EVSE screen for technical parameters
                    of electricity and internet;
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    4.5 You warrant to us that your EV is roadworthy and is
                    registered for use on Albanian roads, that you have read the
                    relevant manufacturer’s EV handbook and that the EV does not
                    present any danger or risk to other vehicles or persons or
                    to the Charging Stations.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    4.6 Where you are not the owner of the EV accessing the
                    Charging Station, you warrant that you are authorised by the
                    owner of that EV to use the Charging Station and that you
                    have authority to bind the owner to these Terms.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    4.7 You acknowledge that we are not supervising your use of
                    the Charging Stations and that you and any people with you
                    are undertaking such use at your own risk.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    4.8 You acknowledge that where Charging Stations are located
                    on private property, you agree to keep the property clean
                    and tidy, and to comply with all reasonable requests of the
                    owner of that property provided from time to time when using
                    the relevant Charging Stations, including any request to
                    immediately suspend, modify or restrict your access to that
                    Charging Station.
                  </span>
                  <br></br>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading5 thq-heading-3">
                  5. EVSE Idle Fee
                </h3>
                <p className="kushtet-e-perdorimit-content5 thq-body-small">
                  <span className="kushtet-e-perdorimit-text263">
                    5.1 You agree that you will remove your EV from a Charging
                    Station promptly once it is charged and ensure that access
                    to the EVSE and Charging Station is cleared to allow other
                    users to use the EVSE and Charging Station.
                  </span>
                  <br className="kushtet-e-perdorimit-text264"></br>
                  <br className="kushtet-e-perdorimit-text265"></br>
                  <span className="kushtet-e-perdorimit-text266">
                    5.2 You acknowledge and agree that you may incur an idle fee
                    for the time your EV remains parked in a charging stall
                    after it is finished charging (Idle Fee). To avoid Idle
                    Fees, we recommend you monitor your EV while using a
                    Charging Station and use the Icharge App to track your EV’s
                    charge status.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading610 thq-heading-3">
                  6. Charging Stations owned by CPO
                </h3>
                <p className="kushtet-e-perdorimit-content610 thq-body-small">
                  <span className="kushtet-e-perdorimit-text268">
                    6.1 When using a Charging Station on the Icharge Network
                    that is operated or owned by a third- party CPO, you will be
                    deemed to have accepted the terms and conditions of use of
                    the third- party CPO that operates or owns the Charging
                    Station (CPO T&amp;Cs).
                  </span>
                  <br className="kushtet-e-perdorimit-text269"></br>
                  <br className="kushtet-e-perdorimit-text270"></br>
                  <span className="kushtet-e-perdorimit-text271">
                    6.2 The third-party CPOs which currently own or operate
                    Charging Stations on the Icharge Network and their terms and
                    conditions of use can be accessed here.
                  </span>
                  <br className="kushtet-e-perdorimit-text272"></br>
                  <br className="kushtet-e-perdorimit-text273"></br>
                  <span className="kushtet-e-perdorimit-text274">
                    6.3 Icharge will use reasonable endeavours to notify you of
                    the Charging Stations on the Icharge Network which are
                    operated or owned by a third-party CPO and provide access to
                    the relevant CPO T&amp;Cs in these Terms and on the Icharge
                    Website and/or Icharge App.
                  </span>
                  <br className="kushtet-e-perdorimit-text275"></br>
                  <br className="kushtet-e-perdorimit-text276"></br>
                  <span className="kushtet-e-perdorimit-text277">
                    6.4 In the event of any inconsistency between these Terms
                    and a CPO T&amp;Cs, the Terms which imposes a higher
                    standard on you will prevail to the extent of any
                    inconsistency.
                  </span>
                  <br className="kushtet-e-perdorimit-text278"></br>
                  <br className="kushtet-e-perdorimit-text279"></br>
                  <span className="kushtet-e-perdorimit-text280">
                    6.5 Icharge does not guarantee that the CPO T&amp;Cs
                    accessible in these Terms or on the Icharge App or Website
                    are the most up to date versions of those CPO T&amp;Cs. You
                    are solely responsible for ensuring that you have read any
                    relevant CPO T&amp;Cs and are aware of any updates that may
                    be made to CPO T&amp;Cs from time to time. By using and
                    continuing to use a Charging Station owned or operated by a
                    third-party CPO you agree to be bound by the most recent
                    terms of the relevant CPO T&amp;Cs.
                  </span>
                  <br className="kushtet-e-perdorimit-text281"></br>
                  <br className="kushtet-e-perdorimit-text282"></br>
                  <span className="kushtet-e-perdorimit-text283">
                    6.6 You agree that Icharge is in no way liable to you
                  </span>
                  <br className="kushtet-e-perdorimit-text284"></br>
                  <br className="kushtet-e-perdorimit-text285"></br>
                  <span className="kushtet-e-perdorimit-text286">
                    (a) under any CPO T&amp;Cs, or
                  </span>
                  <br className="kushtet-e-perdorimit-text287"></br>
                  <br className="kushtet-e-perdorimit-text288"></br>
                  <span className="kushtet-e-perdorimit-text289">
                    (b) in the event the CPO T&amp;Cs accessible in these Terms
                    or on the Icharge App or Website are not the most up to date
                    version of the relevant CPO T&amp;Cs, and you irrevocably
                    release Icharge from any such claim.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading611 thq-heading-3">
                  7. Use of Icharge Apps
                </h3>
                <p className="kushtet-e-perdorimit-content611 thq-body-small">
                  <span className="kushtet-e-perdorimit-text291">
                    Charging Station locations and other content and features
                    that Icharge may make available. Use of the Icharge App and
                    the Website is subject to these Terms, Icharge’s Privacy
                    Policy (available on the Website), any relevant mobile
                    application terms and conditions entered into at the time of
                    downloading the Icharge App.
                  </span>
                  <br className="kushtet-e-perdorimit-text292"></br>
                  <br className="kushtet-e-perdorimit-text293"></br>
                  <span className="kushtet-e-perdorimit-text294">
                    7.2 You are responsible for all use of the Icharge App under
                    your Account name.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading612 thq-heading-3">
                  8. Content of Icharge Apps and Website
                </h3>
                <p className="kushtet-e-perdorimit-content612 thq-body-small">
                  <span>8</span>
                  <span className="kushtet-e-perdorimit-text297">
                    .1 The Icharge App and Website are owned and operated by or
                    on behalf of Icharge.
                  </span>
                  <br className="kushtet-e-perdorimit-text298"></br>
                  <br className="kushtet-e-perdorimit-text299"></br>
                  <span className="kushtet-e-perdorimit-text300">
                    8.2 All intellectual property rights (including copyright
                    and patents) in the information, comment, content,
                    communication, advice, text, trademarks, logos, service
                    names and trade names of Icharge, images of people or places
                    or other content (Content) contained in the Icharge App, on
                    the Website or otherwise provided to you by Icharge is owned
                    or licensed by Icharge.
                  </span>
                  <br className="kushtet-e-perdorimit-text301"></br>
                  <br className="kushtet-e-perdorimit-text302"></br>
                  <span className="kushtet-e-perdorimit-text303">
                    8.3 The Content in the Icharge App and on the Website and
                    any other information provided to you is for general
                    information purposes only. You acknowledge and agree that,
                    while Icharge has attempted to provide accurate information
                    through the Icharge App and on the Website, such information
                    may change frequently and in no event will Icharge be
                    responsible for the accuracy, usefulness or completeness of
                    the Content in the Icharge App and on the Website, nor does
                    it warrant that any such Content is the most current version
                    thereof. To the maximum extent 
                  </span>
                  <span className="kushtet-e-perdorimit-text304">
                    permitted by applicable law, Icharge does not warrant or
                    make any representations as to any third-party products or
                    services described or referred to in the Icharge App, on the
                    Website or otherwise. Any use of the Content by another
                    person or organisation is at the user’s own risk.
                  </span>
                  <br className="kushtet-e-perdorimit-text305"></br>
                  <br className="kushtet-e-perdorimit-text306"></br>
                  <span className="kushtet-e-perdorimit-text307">
                    8.4 The Content in the Icharge App and on the Website is
                    obtained and developed from a variety of sources including
                    but not limited to collaborations with third parties and
                    information provided by third parties under licence or
                    agreement. Inclusion of Content in the Icharge App or on the
                    Website is not an endorsement of any organisation, product
                    or service.
                  </span>
                  <br className="kushtet-e-perdorimit-text308"></br>
                  <br className="kushtet-e-perdorimit-text309"></br>
                  <span className="kushtet-e-perdorimit-text310">
                    8.5 Icharge may modify any information in the Icharge Apps
                    or on the Website (including Content) at our absolute
                    discretion for any reason, without notice. All updates and
                    modifications to the Icharge App or the Website (including
                    Content) will be subject to these Terms.
                  </span>
                  <br className="kushtet-e-perdorimit-text311"></br>
                  <br className="kushtet-e-perdorimit-text312"></br>
                  <span className="kushtet-e-perdorimit-text313">
                    8.6 If you have a complaint regarding any Content in the
                    Icharge App or on the Website, Icharge will review any
                    written complaint notified to it in accordance with clause
                    18 and may, at its sole discretion, modify or remove the
                    relevant Content.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading613 thq-heading-3">
                  9. Links on the Website
                </h3>
                <p className="kushtet-e-perdorimit-content613 thq-body-small">
                  <span className="kushtet-e-perdorimit-text315">
                    9.1 The Website may contain links to other websites
                    (including the websites of CPOs). We have not reviewed all
                    of the third-party websites linked on the Website and are
                    not responsible for their content or accuracy. Icharge
                    provides those website links for reasons such as for
                    searching for third-party goods and services on the internet
                    and, unless otherwise stated, not as an endorsement, support
                    or sponsorship of those websites, their operators, the
                    goods, services or content that they describe.
                  </span>
                  <br className="kushtet-e-perdorimit-text316"></br>
                  <br className="kushtet-e-perdorimit-text317"></br>
                  <span className="kushtet-e-perdorimit-text318">
                    9.2 Social Media and other websites (including the websites
                    of CPOs) which are linked to the Website, are not covered by
                    these Terms, and may have their own terms and conditions and
                    privacy policy. If you choose to access any linked sites,
                    you do so at your own risk. Icharge is not responsible for
                    and will not be liable in respect of the content or
                    operation of those websites or any of the goods, services or
                    content that they describe. Icharge is not responsible for
                    and will not be liable in respect of any incorrect link to
                    an external website.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading614 thq-heading-3">
                  10. Access to Icharge Apps and Website
                </h3>
                <p className="kushtet-e-perdorimit-content614 thq-body-small">
                  <span>1</span>
                  <span className="kushtet-e-perdorimit-text321">
                    0.1 Icharge does not guarantee or warrant that you will have
                    continuous access to the Icharge Apps or Website. Subject to
                    these Terms, Icharge will not be liable if the Icharge App
                    or Website are unavailable to you for any reason, including
                    due to computer downtime attributable to malfunctions,
                    upgrades, preventative or remedial maintenance activities or
                    interruption in telecommunications supply.
                  </span>
                  <br className="kushtet-e-perdorimit-text322"></br>
                  <br className="kushtet-e-perdorimit-text323"></br>
                  <span className="kushtet-e-perdorimit-text324">
                    10.2 Icharge does not guarantee the delivery or security of
                    communications over the internet as such communications rely
                    on third-party service providers, and electronic
                    communication (including electronic mail) is vulnerable to
                    interception by third parties.
                  </span>
                  <br className="kushtet-e-perdorimit-text325"></br>
                  <br className="kushtet-e-perdorimit-text326"></br>
                  <span className="kushtet-e-perdorimit-text327">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br className="kushtet-e-perdorimit-text328"></br>
                  <span className="kushtet-e-perdorimit-text329">
                    10.3 Whilst Icharge takes reasonable precautions to protect
                    information transmitted via the Icharge App and Website,
                    subject to applicable laws, Icharge cannot and does not
                    guarantee the security or confidentiality of these
                    communications or the security of the Icharge App or
                    Website.
                  </span>
                  <br className="kushtet-e-perdorimit-text330"></br>
                  <br className="kushtet-e-perdorimit-text331"></br>
                  <span className="kushtet-e-perdorimit-text332">
                    10.4 Icharge does not provide, and has no control over,
                    communications, networks or services, the internet or other
                    technology required or used across the Icharge App or
                    Website and, to the maximum extent permitted under
                    applicable law, accepts no responsibility for any direct or
                    indirect loss in any form associated with them, whether due
                    to congestion, technical malfunction, viruses or otherwise.
                  </span>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading615 thq-heading-3">
                  11. Indemnity
                </h3>
                <p className="kushtet-e-perdorimit-content615 thq-body-small">
                  <span className="kushtet-e-perdorimit-text333">
                    11.1 You will fully indemnify Icharge in respect of all
                    loss, damage, costs, expenses (including reasonable legal
                    fees on a full indemnity basis), fines, penalties, claims,
                    demands and proceedings howsoever arising, whether at common
                    law (including negligence) or under statute (Loss), in
                    connection with any of the following:
                  </span>
                  <br className="kushtet-e-perdorimit-text334"></br>
                  <br className="kushtet-e-perdorimit-text335"></br>
                  <span className="kushtet-e-perdorimit-text336">
                    (a) any breach of these Terms or a CPO&apos;s T&amp;Cs by
                    you;
                  </span>
                  <br className="kushtet-e-perdorimit-text337"></br>
                  <br className="kushtet-e-perdorimit-text338"></br>
                  <span className="kushtet-e-perdorimit-text339">
                    (b) your use or misuse of the Charging Stations, EVSE,
                    Icharge Network, the Icharge App and the Website; or
                  </span>
                  <br className="kushtet-e-perdorimit-text340"></br>
                  <br className="kushtet-e-perdorimit-text341"></br>
                  <span className="kushtet-e-perdorimit-text342">
                    11.2 Icharge will indemnify you in respect of any Losses
                    incurred by you to the extent such Loss arises directly from
                    a breach of these Terms by Icharge.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading616 thq-heading-3">
                  12. Warranties, consumer guarantees and limitation of
                  liability
                </h3>
                <p className="kushtet-e-perdorimit-content616 thq-body-small">
                  <span>
                    12.1 Your use of the Charging Stations and related EVSE,
                    Icharge Network, Icharge App and Website is at your own
                    risk. Icharge’s services are provided on an ‘as is’ and ‘as
                    available’ basis.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    12.2 Any representation, warranty, condition, guarantee or
                    undertaking that would be implied in these Terms in relation
                    to any service supplied by Icharge by legislation, common
                    law, equity, trade, custom or usage is excluded to the
                    maximum extent permitted by law, including any implied
                    warranties of acceptable quality or fitness for a disclosed
                    purpose.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    12.3 Nothing in these Terms excludes, restricts or modifies
                    any consumer guarantee, right or remedy conferred on you by
                    the Law, or any other applicable law that cannot be
                    excluded, restricted or modified by agreement.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    12.4 Our services come with guarantees that cannot be
                    excluded from the applicable law. For major failures with
                    the service, you are entitled:
                  </span>
                  <br></br>
                  <br></br>
                  <span>(a) to cancel your Account with us; and</span>
                  <br></br>
                  <br></br>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    (b) to a refund for the unused services or credit on your
                    Account;
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    12.5 You are also entitled to be compensated for any other
                    reasonably foreseeable loss or damage. If the failure does
                    not amount to a major failure you are entitled to have
                    problems with the service rectified in a reasonable time
                    and, if this is not done, to cancel your subscription or
                    Account with us and obtain a refund for any unused services
                    or credit on your subscription or Account.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    12.6 Icharge will not be liable to you for any damage of any
                    kind to your EV whatsoever, arising from a failure by you to
                    exercise reasonable care or comply with the requirements of
                    the EV manufacturer’s handbook or with Icharge’s or a
                    CPO&apos;s instructions. To the maximum extent permitted by
                    law, you agree to hold Icharge harmless against any
                    liability, or Losses which you may suffer or incur as a
                    result of a failure by you to comply with one or more of the
                    following:
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    the EV manufacturer’s handbook, Icharge’s or a CPO&apos;s
                    instructions, or your obligations under any applicable laws
                    and regulations.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    12.7 Icharge will not be liable to you for any loss or
                    damage to you or your EV, that are indirect, incidental,
                    special or consequential loss or damage, loss of profits or
                    anticipated profits, economic loss, loss of business
                    opportunity, loss of data, loss of reputation or loss of
                    revenue (irrespective of whether the loss or damage is
                    caused by or relates to breach of contract, tort (including
                    negligence), statute or otherwise) (Consequential Losses)
                    arising in connection with your use of the Icharge Network,
                    the Icharge App, the Website and all links to or from the
                    Website unless, and to the extent, that such Consequential
                    Losses are directly caused by:
                  </span>
                  <br></br>
                  <br></br>
                  <span>(a) the gross negligence of Icharge; or</span>
                  <br></br>
                  <br></br>
                  <span>
                    (b) the fraud or wilful breach by Icharge of any of its
                    obligations under these Terms, provided that such
                    Consequential Losses are reasonably foreseeable and are not
                    caused by
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    something beyond Icharge’s control (including, for the
                    avoidance of doubt, any act or omission
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    of any third-party, any Force Majeure Event (defined under
                    clause 12.10 below), any incompatibility of your EV with the
                    EVSE, the enforcement of parking restrictions by law
                    enforcement officials, or any breach by you of these Terms
                    or a CPOs T&amp;Cs).
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    12.8 You will not be liable to Icharge for any Consequential
                    Losses incurred by Icharge which arise in connection with
                    your use of the Icharge Network, the Icharge App and the
                    Website unless, and to the extent that such Consequential
                    Losses are directly caused by:
                  </span>
                  <br></br>
                  <br></br>
                  <span>(a) your gross negligence; or</span>
                  <br></br>
                  <br></br>
                  <span>
                    (b) your fraud or wilful breach of your obligations under
                    these Terms,
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    provided that such Consequential Losses are reasonably
                    foreseeable and are not caused by something beyond your
                    control (including, for the avoidance of doubt, any act or
                    omission of any third-party or any Force Majeure Event).
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    12.9 Subject to this clause 12, to the maximum extent
                    permitted by law, the maximum aggregate liability of a party
                    for all proven losses, damages and claims arising out of or
                    in connection with 
                  </span>
                  <span>
                    these Terms or a supply under these Terms, including
                    liability for breach, in negligence or in tort or for any
                    other common law or statutory action, is limited to the last
                    charge of the vehicle.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    12.10 For the purposes of this clause 13, Force Majeure
                    Event is defined as a strike, riot, war, flood, bushfire,
                    earthquake or other natural disaster event, pandemic, border
                    closure, state of emergency, any power outage or
                    telecommunication facilities outage.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading617 thq-heading-3">
                  13. Termination of your Account
                </h3>
                <p className="kushtet-e-perdorimit-content617 thq-body-small">
                  <span className="kushtet-e-perdorimit-text407">
                    13.1 Icharge may, acting reasonably, at any time and without
                    prior notice, immediately terminate your Account, including
                    restricting access to the Icharge Network, the Icharge App
                    or any feature of the Icharge App or the Website, for any
                    reason (including due to your breach of these Terms).
                  </span>
                  <br className="kushtet-e-perdorimit-text408"></br>
                  <br className="kushtet-e-perdorimit-text409"></br>
                  <span className="kushtet-e-perdorimit-text410">
                    13.2 You may terminate your Account at any time by providing
                    Icharge with written notice in accordance with clause 18 or
                    by deleting your Account on the Icharge App.
                  </span>
                  <br className="kushtet-e-perdorimit-text411"></br>
                  <br className="kushtet-e-perdorimit-text412"></br>
                  <span className="kushtet-e-perdorimit-text413">
                    13.3 Following any termination, you will remain responsible
                    for any and all unpaid Fees and charges associated with your
                    Account. If such unpaid Fees and charges are not promptly
                    remitted, you may become liable for additional service
                    charges, fees or penalties, and you may be subject to
                    collection actions and fees associated with the recovery by
                    Icharge from you of any unpaid balance.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading618 thq-heading-3">
                  14. Privacy
                </h3>
                <p className="kushtet-e-perdorimit-content618 thq-body-small">
                  <span>12.</span>
                  <span>
                    14.1 In registering for an Account and using the Icharge
                    Network, Icharge App or Website, you may provide certain
                    personal information to Icharge and you agree that any
                    information collected by Icharge may be accessed or
                    collected for use by Icharge or any of its related companies
                    or business partners in the course of its business
                    (including direct marketing activities) in accordance with
                    Icharge’s Privacy Policy (available at the Website and as
                    updated from time to time in accordance with its terms).
                    When using a Charging Station on the Icharge Network that is
                    operated or owned by a third-party CPO, that CPO’s privacy
                    policy will also apply.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    14.2 By using the Icharge Network, Icharge App or Website
                    you agree that Icharge may view, monitor, and record your
                    activity (in accordance with the Icharge Privacy Policy)
                    without providing further notice or requesting your
                    permission. Any data or information obtained by monitoring,
                    reviewing, or recording your activity may be reviewed by law
                    enforcement organisations in connection with investigation
                    or prosecution of possible criminal or unlawful activity on
                    the Icharge Network, Icharge App and Website as well as for
                    disclosures required by or under applicable law or related
                    government agency actions. Icharge will also comply with all
                    court orders or subpoenas involving requests for such data
                    and information.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading619 thq-heading-3">
                  15. Competitors
                </h3>
                <p className="kushtet-e-perdorimit-content619 thq-body-small">
                  If you are in the business of providing similar services to
                  Icharge, whether for business users or domestic users, then
                  you are a competitor of Icharge. Competitors are not permitted
                  to use or access any information or content on the Icharge
                  Website or the Icharge App without Icharge’s written consent.
                  If you breach this clause, Icharge may hold you fully
                  responsible for any Loss that we may sustain from such a
                  breach.
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading620 thq-heading-3">
                  16. Jurisdiction and law
                </h3>
                <p className="kushtet-e-perdorimit-content620 thq-body-small">
                  <span className="kushtet-e-perdorimit-text421">
                    These Terms are governed by and must be construed in
                    accordance with the laws of the Republic of Albania. You
                    submit to the exclusive jurisdiction of the courts of the
                    Republic of Albania and in respect of all matters arising
                    out of or relating to these Terms, their performance and
                    subject matter. The CPO T&amp;Cs may be subject to different
                    jurisdictions.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading621 thq-heading-3">
                  17. Severability
                </h3>
                <p className="kushtet-e-perdorimit-content621 thq-body-small">
                  <span>
                    If any provision or part of a provision of these Terms is
                    held or found to be void, invalid or otherwise
                    unenforceable, it will be deemed to be severed to the extent
                    that it is void, invalid or unenforceable and the remainder
                    of that provision (if applicable) and the other provisions
                    set out in these Terms will remain in full force and effect.
                  </span>
                  <br></br>
                </p>
              </li>
              <li className="list-item">
                <h3 className="kushtet-e-perdorimit-heading622 thq-heading-3">
                  18. Contacting us
                </h3>
                <p className="kushtet-e-perdorimit-content622 thq-body-small">
                  <span>
                    If you have questions about the Icharge Network, the Icharge
                    App, the Website or these Terms, please contact us here.
                  </span>
                  <br></br>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="footer" className="kushtet-e-perdorimit-footer">
        <div className="kushtet-e-perdorimit-content8">
          <div className="kushtet-e-perdorimit-information">
            <div className="kushtet-e-perdorimit-heading">
              <a href="##content" className="kushtet-e-perdorimit-link15">
                <img
                  alt="pastedImage"
                  src="/logo_icharge_primary_page-0002-removebg-preview-200h-200h-200h.png"
                  loading="lazy"
                  className="kushtet-e-perdorimit-pasted-image1"
                />
              </a>
              <span className="kushtet-e-perdorimit-text427">
                <span>Adresa: Rruga e Kavajes,</span>
                <br></br>
                <span>km i 1, Godina ne krahe te Vetingut.</span>
              </span>
            </div>
            <div className="kushtet-e-perdorimit-socials">
              <a
                href="https://www.instagram.com/icharge.alb/"
                target="_blank"
                rel="noreferrer noopener"
                className="kushtet-e-perdorimit-link16"
              >
                <div className="kushtet-e-perdorimit-social1">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    className="kushtet-e-perdorimit-icon14"
                  >
                    <circle
                      r="1.44"
                      cx="22.406"
                      cy="9.594"
                      fill="currentColor"
                    ></circle>
                    <path
                      d="M16 9.838A6.162 6.162 0 1 0 22.162 16A6.16 6.16 0 0 0 16 9.838M16 20a4 4 0 1 1 4-4a4 4 0 0 1-4 4"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M16 6.162c3.204 0 3.584.012 4.849.07a6.6 6.6 0 0 1 2.228.413a3.98 3.98 0 0 1 2.278 2.278a6.6 6.6 0 0 1 .413 2.228c.058 1.265.07 1.645.07 4.85s-.012 3.583-.07 4.848a6.6 6.6 0 0 1-.413 2.228a3.98 3.98 0 0 1-2.278 2.278a6.6 6.6 0 0 1-2.228.413c-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07a6.6 6.6 0 0 1-2.228-.413a3.98 3.98 0 0 1-2.278-2.278a6.6 6.6 0 0 1-.413-2.228c-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849a6.6 6.6 0 0 1 .413-2.228a3.98 3.98 0 0 1 2.278-2.278a6.6 6.6 0 0 1 2.228-.413c1.265-.058 1.645-.07 4.849-.07M16 4c-3.259 0-3.668.014-4.948.072a8.8 8.8 0 0 0-2.912.558a6.14 6.14 0 0 0-3.51 3.51a8.8 8.8 0 0 0-.558 2.913C4.014 12.333 4 12.74 4 16s.014 3.668.072 4.948a8.8 8.8 0 0 0 .558 2.912a6.14 6.14 0 0 0 3.51 3.51a8.8 8.8 0 0 0 2.913.558c1.28.058 1.688.072 4.947.072s3.668-.014 4.948-.072a8.8 8.8 0 0 0 2.913-.558a6.14 6.14 0 0 0 3.51-3.51a8.8 8.8 0 0 0 .557-2.913C27.986 19.667 28 19.26 28 16s-.014-3.668-.072-4.948a8.8 8.8 0 0 0-.558-2.912a6.14 6.14 0 0 0-3.51-3.51a8.8 8.8 0 0 0-2.913-.557C19.667 4.013 19.26 4 16 4"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.tiktok.com/@icharge.app"
                target="_blank"
                rel="noreferrer noopener"
                className="kushtet-e-perdorimit-link17"
              >
                <div className="kushtet-e-perdorimit-social2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="kushtet-e-perdorimit-icon18"
                  >
                    <path
                      d="M16.6 5.82s.51.5 0 0A4.28 4.28 0 0 1 15.54 3h-3.09v12.4a2.59 2.59 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.linkedin.com/feed/"
                target="_blank"
                rel="noreferrer noopener"
                className="kushtet-e-perdorimit-link18"
              >
                <div className="kushtet-e-perdorimit-social3">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="kushtet-e-perdorimit-icon20"
                  >
                    <g fill="currentColor">
                      <path
                        d="M12.51 8.796v1.697a3.74 3.74 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766c-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483a1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.6 1.6 0 0 1 1.6 1.606"
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                      ></path>
                      <path d="M7.2 8.809H4V19.5h3.2z"></path>
                    </g>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.facebook.com/icharge.alb"
                target="_blank"
                rel="noreferrer noopener"
                className="kushtet-e-perdorimit-link19"
              >
                <div className="kushtet-e-perdorimit-social4">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="kushtet-e-perdorimit-icon24"
                  >
                    <path
                      d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6z"
                      fill="currentColor"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
          <div className="kushtet-e-perdorimit-links2">
            <div className="kushtet-e-perdorimit-column1">
              <span className="kushtet-e-perdorimit-header2">iCharge.app</span>
              <Link to="/" className="kushtet-e-perdorimit-link20">
                Home
              </Link>
              <Link to="/" className="kushtet-e-perdorimit-link21">
                Rreth nesh
              </Link>
              <a
                href="https://m.icharge.app/#/homepage"
                target="_blank"
                rel="noreferrer noopener"
                className="kushtet-e-perdorimit-link22"
              >
                Pika Karikimi
              </a>
              <Link
                to="/kushtet-e-perdorimit"
                className="kushtet-e-perdorimit-link23"
              >
                Kushtet e perdorimit
              </Link>
              <Link
                to="/politikat-e-privatesise"
                className="kushtet-e-perdorimit-link24"
              >
                Politikat e privatesise
              </Link>
            </div>
            <div className="kushtet-e-perdorimit-column2">
              <span className="kushtet-e-perdorimit-header3">Kontakt</span>
              <a
                href="tel:+355694080227"
                className="kushtet-e-perdorimit-link25"
              >
                Tel: +355694080227
              </a>
              <a
                href="mailto:office@icharge.app?subject="
                className="kushtet-e-perdorimit-link26"
              >
                Email: office@icharge.app
              </a>
            </div>
          </div>
        </div>
        <div className="kushtet-e-perdorimit-pills">
          <div className="kushtet-e-perdorimit-container4">
            <div className="kushtet-e-perdorimit-you-tube1">
              <a
                href="https://apps.apple.com/lv/app/icharge-app/id6479523452"
                target="_blank"
                rel="noreferrer noopener"
                className="kushtet-e-perdorimit-link27"
              >
                <img
                  alt="pastedImage"
                  src="/Icons/download%20(3)-600w.png"
                  className="kushtet-e-perdorimit-pasted-image2"
                />
              </a>
            </div>
            <div className="kushtet-e-perdorimit-you-tube2">
              <a
                href="https://play.google.com/store/apps/details?id=com.icharge.mobile.app"
                target="_blank"
                rel="noreferrer noopener"
                className="kushtet-e-perdorimit-link28"
              >
                <img
                  alt="pastedImage"
                  src="/Icons/download%20(4)-200h.png"
                  className="kushtet-e-perdorimit-pasted-image3"
                />
              </a>
            </div>
          </div>
        </div>
        <span className="kushtet-e-perdorimit-text431">
          © 2024 iCharge. All Rights Reserved.
        </span>
      </div>
    </div>
  )
}

export default KushtetEPerdorimit
